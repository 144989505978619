import React from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./pages/route";
import "./App.css";

const App = () => {
  // eslint-disable-next-line no-undef
  if (urlTracking) {
    // eslint-disable-next-line no-undef
    urlTracking = process.env.REACT_APP_BASE_URL;
  }

  return <RouterProvider router={routes} />;
};

export default App;
