import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import './SecondStepJobListing.scss';
import { PayOptionConstant } from '../../../constants/JobConstant';
import { JobOfferingContext } from '../../../context/JobOfferringContext';
import FireTracking from '../../../assets/FireTracking/fireTracking';
import { TYPE_TRACKING } from '../../../constants/TrackingConstant';
import { JobApi } from '../../../api/jobApi';
import DefaultImage from '../../../assets/images/building.jpg';
import useQueryHook from '../../../customHooks/useQueryHook';

const SecondStepJobListing = () => {
    const baseSerpUrl = process.env.REACT_APP_SERP_BASE_URL;

    const { jobs, setJobs } = useContext(JobOfferingContext);

    const { queryString, queryParams, pixelFire } = useQueryHook();
    const keyword = queryParams.get('keyword') || queryParams.get('query');
    const campaignId = queryParams.get('camp_id');
    const [currentJobIndex, setCurrentJobIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [skipCount, setSkipCount] = useState(0);
    const [tinyJobsArray, setTinyJobsArray] = useState([]);
    const params = JSON.parse(localStorage.getItem('job-genius-user-info'));
    const currentJob = jobs[currentJobIndex];
    let userLocation = useRef("");
    let userState = useRef("")

    const testObj = useMemo(() => {
        const weightKeyword = keyword ? 0.1 : 0;

        return {
            keyword,
            campaignId,
            testing: false,
            isFilterDistance: false,
            distance: 100,
            weightKeyword,
            weightBid: 0.3,
            weightCR: 0.2,
            weightGEO: 0.4,
            page: 0,
            size: 6 // Assuming up to 6 jobs
        }
    }, [keyword, campaignId]);

    const redirectToSERP = () => {
        let locationParams = '';
        if (userLocation.current && userState.current) {
            locationParams = `${userLocation.current},${userState.current}`; // Both city and state are present
        } else if (userLocation.current) {
            locationParams = `${userLocation.current}`;// Only city is present
        } else if (userState.current) {
            locationParams = `${userState.current}`; // Only state is present
        }
        window.location.href = `${baseSerpUrl}/${queryString || '?'}&query=${keyword || ""}&job_location=${locationParams}`;

    }

    const handleSkip = (currJob) => {
        const dataTracking = {
            jobId: currJob?.jobId,
            majorCategory: currJob?.majorCategory,
            conversion: 0
        };
        FireTracking(TYPE_TRACKING.applyJob, dataTracking);
        if (currentJobIndex >= 5 || currentJobIndex >= jobs.length - 1) {
            pixelFire("eof")
            redirectToSERP();
            return;
        }

        if (skipCount >= 2) {
            // Show FixedTinyJobArray after 3 skips, limited to 6 jobs total
            if (tinyJobsArray.length > 0) {
                const tinyJobs = tinyJobsArray.slice(0, 5 - currentJobIndex);
                const remainingJobs = jobs.slice(0, currentJobIndex + 1)
                setJobs([...remainingJobs, ...tinyJobs]);
            }
            setCurrentJobIndex(prevIndex => prevIndex + 1);
            setSkipCount(0);
        } else {
            setCurrentJobIndex(prevIndex => prevIndex + 1);
            setSkipCount(prev => prev + 1);
        }
    };

    const handleApply = (currJob) => {
        try {
            setSkipCount(0);
            // Implement logic for pixel fire
            const dataTracking = {
                jobId: currJob?.jobId,
                majorCategory: currJob?.majorCategory,
                conversion: 1
            }
            FireTracking(TYPE_TRACKING.applyJob, dataTracking);
        } catch (error) {
            console.log("Error while firing apply event", error)
        }
        setCurrentJobIndex(prevIndex => Math.min(prevIndex + 1, jobs.length - 1));
        const windowAddress = params
            ? `${currJob.shaUrl}${queryString ? `${queryString}&first_name=${params.firstName}&last_name=${params.lastName}&email=${params.email}&phone=${params.phoneNumber}` : `?first_name=${params.firstName}&last_name=${params.lastName}&email=${params.email}&phone=${params.phoneNumber}`}`
            : `${currJob.shaUrl}${queryString || ''}`;
        window.open(windowAddress);

        if (currentJobIndex >= 5 || currentJobIndex >= jobs.length - 1) {
            pixelFire("eof");
            redirectToSERP();
        }
    };


    const fetchData = useCallback(async () => {
        setLoading(true);

        try {
            // Run both API calls in parallel
            const jobDataResponse = await JobApi.getJobApi({ ...testObj });
            const specificJobData = jobDataResponse?.data?.specificJobResult?.content || [];
            const jobData = jobDataResponse?.data?.normalJobResult?.content || [];
            const tinyJobData = jobDataResponse?.data?.tinyJobResult?.content || [];
            userLocation.current = jobDataResponse?.data?.userCity || "";
            userState.current = jobDataResponse?.data?.userState || ""
            // If fetchedJobs are less than 6, concat with tinyJobData
            const combinedJobs = jobData.length < 6
                ? [...specificJobData, ...jobData, ...tinyJobData.slice(0, 6 - jobData.length)]
                : [...specificJobData, ...jobData];

            setJobs(combinedJobs);
            setTinyJobsArray(tinyJobData)
        } catch (err) {
            console.error('Error fetching job data:', err);
        } finally {
            setLoading(false);
        }
    }, [testObj, setJobs]);


    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [testObj]);
    return (
        <div>
            {loading ? (
                <div className='loading-spinner'>
                    <p>Loading...</p>
                </div>
            ) : currentJob ? (
                <div className='job-browser'>
                    <div className='company-image'>
                        <img src={currentJob.companyLogo || DefaultImage} alt="companyLogo" loading='lazy' />
                    </div>
                    <div className='job-listing'>
                        {currentJob.jobType ?
                            <p className='job'>
                                {`Apply for ${keyword ? keyword : ''} Jobs in ${userLocation.current || 'global'}`}
                            </p>
                            :
                            <p className='job'>
                                {`${currentJob.title} in ${currentJob.postalCode || 'global'} up to $${currentJob.payTo || currentJob.payFrom || '0'}/${PayOptionConstant[currentJob.payOption] || "hr"}`}
                            </p>
                        }
                    </div>
                    <hr />
                    <div className='footer-job-button'>
                        <button className='view-job' onClick={() => handleApply(currentJob)}>Apply</button>
                        <button className='skip-job' onClick={() => handleSkip(currentJob)}>Skip</button>
                    </div>
                </div>
            ) : (
                <p>No more jobs to show</p>
            )}
        </div>
    );
};

export default SecondStepJobListing;
