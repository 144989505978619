import { useLocation } from "react-router-dom"
import pixelFiringFunction from "../assets/FireTracking/imagePixelTracking";

const useQueryHook = () => {

    const location = useLocation();
    const queryString = location.search;
    const queryParams = new URLSearchParams(location?.search);

    const cid = queryParams.get('jgcid');
    const txid = queryParams.get('txid');
    const payout = queryParams.get('payout');

    const pixelFire = (et) => {
        pixelFiringFunction(cid, et ,txid, payout)
    }

    return { queryString, location, queryParams, pixelFire }

};

export default useQueryHook;